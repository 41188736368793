export const PLAN_ZONE_NAMES = {
  'Zone 1': '1',
  'Zone 2': '2',
  'USA All': 'usaall',
  'USA TMo': 'usa',
  Global: 'global',
  'Global Excluding Canada': 'globalexclca',
  Canada: 'canada',
  'Canada and USA': 'canada_usa',
};

export const CARRIER_DEFAULTS = {
  JT: {
    displayName: 'JT',
    carriers: {
      2: {
        csp: 'Konekt Z2 PAYU',
        apn: 'hologram',
      },
    },
  },
  TMo: {
    displayName: 'TMo',
    carriers: {
      5: {
        csp: 'Hologram 1',
        apn: 'hologram',
      },
    },
  },
  JTTmo: {
    displayName: 'JT & TMo',
    carriers: {
      2: {
        csp: 'Konekt Z2 PAYU',
        apn: 'hologram',
      },
      5: {
        csp: 'Hologram 1',
        apn: 'hologram',
      },
    },
  },
  JTBrazil: {
    displayName: 'JT Brazil',
    carriers: {
      4: {
        csp: 'Hologram Algar',
        apn: 'hologram',
      },
    },
  },
  JTUSAPoP: {
    displayName: 'JT USA PoP',
    carriers: {
      2: {
        csp: 'Hologram NA Indigo',
        apn: 'Indigo',
      },
    },
  },
  Flolive: {
    displayName: 'Flolive',
    carriers: {
      8: {
        csp: '1012',
        apn: 'hologram',
      },
    },
  },
  JTFlolive: {
    displayName: 'JT & Flo',
    carriers: {
      2: {
        csp: 'Konekt Z2 PAYU',
        apn: 'hologram',
      },
      8: {
        csp: '1012',
        apn: 'hologram',
      },
    },
  },
  Telus: {
    displayName: 'Telus',
    carriers: {
      11: {
        csp: 'HOLOGRAM IoT Low Usage#hologram-hologram-DS-CA',
        apn: 'hologram',
      },
    },
  },
  Telenor: {
    displayName: 'Telenor',
    carriers: {
      13: {
        csp: 'C-150259_SP_04',
        apn: 'hologram',
      },
    },
  },
};

export const TRANSACTION_TYPE_NAMES = {
  'Promo Credit': 'PC',
  'Overbilling Correction': 'OC',
  Payment: 'P',
  Refund: 'R',
  Order: 'OR',
  APN: 'APN',
};

export const TRANSACTION_TYPES = {
  'Promo Credit': ['Promo Credit'],
  Account: ['Overbilling Correction', 'Payment'],
  Charge: ['Refund', 'Order', 'APN'],
};
